import { mdiMusic, mdiMusicNote } from '@mdi/js';
import Icon from '@mdi/react';
import { Box } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const Link = ({ path, title, icon, style }) => {
    return (
        <RouterLink
            to={path}
            style={{
                width: '80%',
                display: 'inherit',
                margin: 'auto',
                textDecoration: 'none',
                color: 'black',
                ...style,
            }}
        >
            <Box
                sx={{
                    width: 'calc(100% - 2 * 16px)',
                    border: '1px solid black',
                    borderRadius: '10px',
                    fontSize: '10vw',
                    lineHeight: '9vw',
                    fontWeight: 'bold',
                    fontVariant: 'small-caps',
                    padding: '16px',
                    display: 'flex',
                    justifyContent: 'center',
                    '&:hover': {
                        color: 'darkred',
                    },
                }}
            >
                <Icon
                    path={icon}
                    size={'10vw'}
                    style={{
                        color: 'inherit',
                        margin: 'auto 0',
                        marginRight: '2vw',
                    }}
                />
                <Box>{title}</Box>
            </Box>
        </RouterLink>
    );
};

const Home = () => {
    return (
        <div
            style={{
                position: 'absolute',
                top: '50%',
                transform: 'translate(0%, -50%)',
                width: '100%',
            }}
        >
            <Link path={'/playlists'} title={'Playlist'} icon={mdiMusic} />
            <Link
                style={{ marginTop: '10vh' }}
                path={'/tracks'}
                title={'Track'}
                icon={mdiMusicNote}
            />
        </div>
    );
};

export default Home;
