import {
    QUEUE_GET_FAIL,
    QUEUE_GET_SUCCESS,
    QUEUE_LOADING,
    QUEUE_NEXT,
    QUEUE_NAME,
} from '../actions/types';

const initialState = {
    items: [],
    played: [],
    name: '',
    progress: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case QUEUE_GET_FAIL:
            return {
                ...state,
                items: [],
                name: '',
                progress: false,
            };
        case QUEUE_GET_SUCCESS:
            return {
                ...state,
                ...action.payload,
                played: [],
                progress: false,
            };
        case QUEUE_LOADING:
            return {
                ...state,
                progress: true,
            };
        case QUEUE_NAME:
            return {
                ...state,
                ...action.payload,
                progress: true,
            };
        case QUEUE_NEXT:
            return {
                ...action.payload,
                ...state,
            };
        default:
            return state;
    }
};

export default reducer;
