import { useSelector } from 'react-redux';

function PrivateRoute(props) {
    const isAuthenticated = useSelector((state) => state.auth.token);

    if (isAuthenticated) {
        return props.children;
    } else {
        window.open(
            `${process.env.REACT_APP_API_URL}/login${
                window.location.pathname !== '/'
                    ? `?redirect=${window.location.pathname}`
                    : ''
            }`,
            '_self'
        );
    }
}

export default PrivateRoute;
