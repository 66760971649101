import axios from 'axios';

const api = axios.create({
    baseURL: 'https://isrc-api.soundexchange.com/api/ext',
});

// Add a request interceptor
api.interceptors.request.use(
    async (config) => {
        // const token = store.getState().auth.token;
        // if (token) {
        //     config.headers['Authorization'] = `Bearer ${token}`;
        // }
        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

// Add a response interceptor
api.interceptors.response.use(
    (response) => {
        // request was successfull
        return response;
    },
    async (error) => {
        const originalRequest = error.config;
        if (error.response.status === 401) {
            // request to refresh the token
            api.get('/login')
                .then((res) => {
                    if (res.status === 200) {
                        // store.dispatch({
                        //     type: AUTH_SUCCESS,
                        //     payload: {
                        //         token: res.data.token,
                        //         refreshToken: res.data.refreshToken,
                        //     },
                        // });
                        api.defaults.headers.common['Authorization'] =
                            'Token ' + res.data.token;
                        return api(originalRequest)
                            .then((res) => {
                                originalRequest.success(res);
                            })
                            .catch((err) => {
                                console.error(err);
                                originalRequest.error(err);
                            });
                    }
                    return Promise.reject(error);
                })
                .catch((err) => {
                    // request failed, token could not be refreshed
                    // store.dispatch({
                    //     type: AUTH_FAIL,
                    // });
                    return Promise.reject(err);
                });
        }
        // request status was unequal to 401, no possibility to refresh the token
        return Promise.reject(error);
    }
);

export default api;
