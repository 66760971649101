import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { getQueue, play, pause, setNext } from '../actions/queueActions';

import { Link, useParams } from 'react-router-dom';

import Vinyl from './Vinyl';

import QRCode from 'qrcode';
import { useTour } from '@reactour/tour';

import Icon from '@mdi/react';
import {
    mdiPlay,
    mdiPause,
    mdiArrowLeftBoldCircleOutline,
    mdiQrcode,
    mdiSkipNextCircle,
    mdiClipboardList,
    mdiCog,
    mdiMusicCircle,
    mdiHelpCircleOutline,
} from '@mdi/js';

import {
    Alert,
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    List,
    ListItem,
    ListItemText,
    Popover,
    Snackbar,
    Switch,
} from '@mui/material';
import Cover from './Cover';

import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>,
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Player = ({ type }) => {
    const id = useParams().id;
    const { setIsOpen, currentStep, isOpen } = useTour();

    const dispatch = useDispatch();
    const token = useSelector((state) => state.auth.token);
    const name = useSelector((state) => state.queue.name);
    const queue = useSelector((state) => state.queue.items);
    const playedQueue = useSelector((state) => state.queue.played);
    const progress = useSelector((state) => state.queue.progress);

    const [device, setDevice] = useState(undefined);
    const [played, setPlayed] = useState(false);
    // const [skip, setSkip] = useState(true);
    const [autoPlay, setAutoPlay] = useState(false);
    const [separate, setSeparate] = useState(true);
    const [solve, setSolve] = useState(false);

    const [open, setOpen] = useState(false);
    const [track, setTrack] = useState(null);
    const [last, setLast] = useState(null);
    const [settings, setSettings] = useState(null);
    const [notification, setNotification] = useState('');

    useEffect(() => {
        dispatch(getQueue(id, type));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (queue.length > 0 && id === '69OSkOP8X15SsNSLi9OOfT') {
            setIsOpen(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queue]);

    useEffect(() => {
        if (currentStep !== 4 || currentStep !== 10 || currentStep !== 8) {
            if (open) {
                setOpen(false);
            } else if (solve) {
                setSolve(false);
            } else if (settings) {
                setSettings(false);
            }
        }
        if (currentStep === 4) {
            setSolve(true);
        }
        if (currentStep === 10) {
            setOpen(true);
        }
        if (currentStep === 8) {
            setSettings(true);
        }
    }, [currentStep]);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://sdk.scdn.co/spotify-player.js';
        script.async = true;

        document.body.appendChild(script);

        window.onSpotifyWebPlaybackSDKReady = () => {
            const player = new window.Spotify.Player({
                name: 'Lucster',
                getOAuthToken: (cb) => {
                    cb(token);
                },
                volume: 0.5,
            });

            player.addListener('ready', ({ device_id }) => {
                setDevice(device_id);
                document.body.addEventListener('click', (e) => {
                    if (!played) {
                        player.activateElement();
                    }
                });
                // setNotification('Ready with Device ID');
            });

            player.addListener('not_ready', ({ device_id }) => {
                // setNotification('Device ID has gone offline');
            });

            player.addListener(
                'player_state_changed',
                ({
                    position,
                    duration,
                    track_window: { current_track },
                    paused,
                }) => {
                    // if (!(paused && played === undefined)) {
                    setPlayed(!paused);
                    // }
                    // console.log('Currently Playing', current_track);
                    // if (track === current_track.id) {
                    //     console.log('bereits gespielt');
                    //     setTrackPosition(position);
                    // } else {
                    //     console.log('noch zu spielen');
                    //     setPosition(position);
                    // }
                    // console.log('Position in Song', position);
                    // console.log('Duration of Song', duration);
                }
            );

            player.addListener('autoplay_failed', () => {
                setNotification('Autoplay ist in deinem Browser nicht erlaubt');
            });

            player.on('initialization_error', ({ message }) => {
                setNotification('Initialisierung fehlgeschlagen');
            });

            player.on('authentication_error', ({ message }) => {
                // alert(message);
                setNotification('Authentifizierung fehlgeschlagen');
            });

            player.on('account_error', ({ message }) => {
                setNotification(
                    'Überprüfung des Spotify-Kontos fehlgeschlagen'
                );
            });

            player.on('playback_error', ({ message }) => {
                setNotification('Wiedergabe fehlgeschlagen');
            });

            player.connect();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);

    const next = async () => {
        // eslint-disable-next-line
        const nextQueue = await dispatch(setNext());
        if (queue.length > 0) {
            if (autoPlay) {
                play(device, queue[0].uri);
            } else {
                setLast(playedQueue[0].id);
                pause(device);
            }
        } else {
            pause(device);
            setOpen(true);
        }
    };

    const playTrack = (uri, position) => {
        play(
            device,
            uri,
            // ||
            //     (skip &&
            //         (queue[0].uri)),
            position
        );
        // skip && setSkip(false);
    };

    const generateQR = () => {
        QRCode.toDataURL(
            window.location.href.split('#')[0],
            {
                margin: 1,
                color: {
                    light: '#fff',
                    dark: '#8b0000',
                },
                scale: 6,
                errorCorrectionLevel: 'Q',
            },
            function (error, uri) {
                if (error) {
                    console.error(error);
                    setNotification('QR-Code konnte nicht generiert werden.');
                } else {
                    var link = document.createElement('a');
                    link.download = `${type}_${id}.png`;
                    link.href = uri;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            }
        );
    };

    return (
        <div id="player" style={{ width: '100vw', height: '100vh' }}>
            <Snackbar
                open={notification !== ''}
                autoHideDuration={5000}
                onClose={() => setNotification('')}
                message={notification}
                ContentProps={{
                    sx: {
                        borderRadius: 0,
                        background: 'darkred',
                    },
                }}
            />
            <Button
                component={Link}
                to={window.location.pathname.split('/').slice(0, 2).join('/')}
                sx={{
                    background: 'transparent',
                    minWidth: '40px',
                    height: '40px',
                    padding: 0,
                    borderRadius: '50%',
                    position: 'absolute',
                    left: '20px',
                    top: '20px',
                    marginBottom: '8px',
                    color: 'darkred',
                    '&:hover': {
                        color: 'black',
                    },
                }}
            >
                <Icon
                    path={mdiArrowLeftBoldCircleOutline}
                    size={1.7}
                    style={{ color: 'inherit' }}
                />
            </Button>
            <Button
                onClick={() => setIsOpen(true)}
                sx={{
                    background: 'transparent',
                    minWidth: '40px',
                    height: '40px',
                    padding: 0,
                    borderRadius: '50%',
                    position: 'absolute',
                    right: '20px',
                    top: '20px',
                    marginBottom: '8px',
                    color: 'lightgrey',
                    '&:hover': {
                        color: 'black',
                    },
                }}
                disabled={isOpen}
            >
                <Icon
                    path={mdiHelpCircleOutline}
                    size={1.7}
                    style={{ color: 'inherit' }}
                />
            </Button>

            {!progress ? (
                <>
                    {device ? (
                        queue.length > 0 ? (
                            <>
                                <Vinyl
                                    style={{
                                        top: 'calc(50% - 30px)',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        position: 'absolute',
                                    }}
                                    onClick={() => {
                                        if (played) {
                                            setLast(queue[0].id);
                                            pause(device);
                                        } else {
                                            playTrack(
                                                last === queue[0].id
                                                    ? null
                                                    : queue[0].uri
                                            );
                                        }
                                    }}
                                    spin={played && !track}
                                >
                                    <Icon
                                        path={
                                            played && !track
                                                ? mdiPause
                                                : mdiPlay
                                        }
                                        size={2}
                                        style={{ color: 'white' }}
                                    />
                                </Vinyl>
                                {separate ? (
                                    <Button
                                        id="solve"
                                        onClick={() => setSolve(true)}
                                        sx={{
                                            display: solve ? 'none' : 'inherit',
                                            background: 'transparent',
                                            minWidth:
                                                'calc(min(50vh, 75vw) / 4)',
                                            height: 'calc(min(50vh, 75vw) / 4)',
                                            padding: 0,
                                            borderRadius: '50%',
                                            position: 'fixed',
                                            right: 'calc(50% - 30px + (min(50vh, 75vw) / 2))',
                                            bottom: 'calc(50% - (min(50vh, 75vw) / 2))',
                                            marginTop: '8px',
                                            color: 'darkred',
                                            '&:hover': {
                                                color: 'black',
                                            },
                                        }}
                                    >
                                        <Icon
                                            path={mdiMusicCircle}
                                            size={'calc(min(50vh, 75vw) / 4)'}
                                            style={{ color: 'inherit' }}
                                        />
                                    </Button>
                                ) : null}
                                <Button
                                    id="next"
                                    onClick={() =>
                                        separate ? next() : setSolve(true)
                                    }
                                    sx={{
                                        display: solve ? 'none' : 'inherit',
                                        background: 'transparent',
                                        minWidth: 'calc(min(50vh, 75vw) / 4)',
                                        height: 'calc(min(50vh, 75vw) / 4)',
                                        padding: 0,
                                        borderRadius: '50%',
                                        position: 'fixed',
                                        left: 'calc(50% - 30px + (min(50vh, 75vw) / 2))',
                                        bottom: 'calc(50% - (min(50vh, 75vw) / 2))',
                                        marginTop: '8px',
                                        color: 'darkred',
                                        '&:hover': {
                                            color: 'black',
                                        },
                                    }}
                                >
                                    <Icon
                                        path={mdiSkipNextCircle}
                                        size={'calc(min(50vh, 75vw) / 4)'}
                                        style={{ color: 'inherit' }}
                                    />
                                </Button>
                                <Dialog
                                    id="solveDialog"
                                    onClose={() => {
                                        setSolve(false);
                                        if (!separate) {
                                            setTimeout(() => {
                                                next();
                                            }, 1000);
                                        }
                                    }}
                                    open={solve}
                                    TransitionComponent={Transition}
                                    transitionDuration={1000}
                                    maxWidth="sm"
                                    fullWidth
                                    PaperProps={{
                                        sx: {
                                            borderRadius: 0,
                                            padding: 0,
                                            width: 'min(50vh, 75vw)',
                                            height: 'min(50vh, 75vw)',
                                            marginTop: '130px',
                                        },
                                    }}
                                    sx={{
                                        '&.MuiModal-backdrop': {
                                            background: 'transparent',
                                        },
                                    }}
                                >
                                    <DialogContent sx={{ padding: 0 }}>
                                        {queue[0].album.images?.length > 0 ? (
                                            <Cover
                                                size="100%"
                                                hover={false}
                                                image={
                                                    queue[0].album.images[0].url
                                                }
                                                title={
                                                    <div
                                                        style={{
                                                            fontSize: '3vh',
                                                        }}
                                                    >
                                                        {queue[0].name}
                                                        <br />
                                                        {queue[0].artists
                                                            .map((a) => a.name)
                                                            .join(', ')}
                                                        <br />
                                                        {queue[0].external_ids
                                                            ?.isrc ? (
                                                            <a
                                                                href={`https://isrc.soundexchange.com/?tab=%22code%22&isrcCode=%22${queue[0].external_ids?.isrc}%22`}
                                                                target="_blank"
                                                                rel="noreferrer"
                                                                style={{
                                                                    textDecoration:
                                                                        'none',
                                                                    color: 'white',
                                                                    fontWeight:
                                                                        'bold',
                                                                }}
                                                            >
                                                                {queue[0].album.release_date.slice(
                                                                    0,
                                                                    4
                                                                )}
                                                            </a>
                                                        ) : (
                                                            queue[0].album.release_date.slice(
                                                                0,
                                                                4
                                                            )
                                                        )}
                                                    </div>
                                                }
                                            />
                                        ) : null}
                                    </DialogContent>
                                </Dialog>
                            </>
                        ) : (
                            <Vinyl
                                style={{
                                    top: 'calc(50% - 30px)',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    position: 'absolute',
                                }}
                                onClick={() => {
                                    setOpen(true);
                                }}
                                spin={false}
                            ></Vinyl>
                        )
                    ) : null}
                    <Button
                        id="historyButtton"
                        onClick={() => {
                            if (played) pause(device);
                            setOpen(true);
                        }}
                        sx={{
                            background: 'transparent',
                            minWidth: '40px',
                            height: '40px',
                            padding: 0,
                            borderRadius: '50%',
                            position: 'absolute',
                            left: '80px',
                            bottom: '20px',
                            marginTop: '8px',
                            color: 'black',
                            '&:hover': {
                                color: 'darkred',
                            },
                        }}
                    >
                        <Icon
                            path={mdiClipboardList}
                            size={1.4}
                            style={{ color: 'inherit' }}
                        />
                    </Button>
                    <Dialog
                        onClose={() => {
                            if (track) {
                                pause(device);
                            }
                            setTrack(null);
                            setOpen(false);
                        }}
                        open={open}
                        maxWidth="sm"
                        fullWidth
                        PaperProps={{ id: 'history', sx: { borderRadius: 0 } }}
                    >
                        <DialogTitle>
                            gespielte Tracks ({playedQueue.length} von{' '}
                            {queue.length + playedQueue.length})
                            {type === 'playlist'
                                ? ` aus Playlist "${name}"`
                                : ''}
                        </DialogTitle>
                        <DialogContent>
                            {queue.length === 0 ? (
                                <Alert severity="info">
                                    Du hast alle Lieder abgespielt.
                                </Alert>
                            ) : null}
                            {playedQueue.length === 0 ? (
                                <Alert severity="error">
                                    Du hast dir noch kein Lied angehört. Sobald
                                    du ein Lied abgespielt, gelöst und den
                                    nachfolgenden Track aufgerufen hast, wird
                                    dir die Historie hier angezeigt.
                                </Alert>
                            ) : null}
                            <List>
                                {playedQueue.map((q, i) => (
                                    <ListItem
                                        key={i}
                                        divider={playedQueue.length - 1 !== i}
                                        disableGutters
                                    >
                                        <ListItemText
                                            primaryTypographyProps={{
                                                sx: { display: 'flex' },
                                            }}
                                        >
                                            <Button
                                                onClick={() => {
                                                    if (track === q.id) {
                                                        setLast(track);
                                                        setTrack(null);
                                                        pause(device);
                                                    } else {
                                                        setLast(track);
                                                        setTrack(q.id);
                                                        playTrack(
                                                            last === q.id
                                                                ? null
                                                                : q.uri
                                                        );
                                                    }
                                                }}
                                                sx={{
                                                    minWidth: '40px',
                                                    height: '40px',
                                                    padding: 0,
                                                    borderRadius: '50%',
                                                    color: 'white',
                                                    background: 'darkred',
                                                    marginRight: '20px',
                                                    border: '1px solid darkred',
                                                    '&:hover': {
                                                        color: 'darkred',
                                                        background: 'white',
                                                    },
                                                }}
                                            >
                                                <Icon
                                                    path={
                                                        track === q.id
                                                            ? mdiPause
                                                            : mdiPlay
                                                    }
                                                    size={1.4}
                                                    style={{ color: 'inherit' }}
                                                />
                                            </Button>
                                            <div style={{ margin: 'auto 0' }}>
                                                {q.name} (
                                                {q.external_ids?.isrc ? (
                                                    <a
                                                        href={`https://isrc.soundexchange.com/?tab=%22code%22&isrcCode=%22${q.external_ids?.isrc}%22`}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        style={{
                                                            textDecoration:
                                                                'none',
                                                            color: 'black',
                                                        }}
                                                    >
                                                        {q.album.release_date.slice(
                                                            0,
                                                            4
                                                        )}
                                                    </a>
                                                ) : (
                                                    q.album.release_date.slice(
                                                        0,
                                                        4
                                                    )
                                                )}
                                                ) von{' '}
                                                {q.artists
                                                    .map((a) => a.name)
                                                    .join(', ')}
                                            </div>
                                        </ListItemText>
                                    </ListItem>
                                ))}
                            </List>
                        </DialogContent>
                    </Dialog>
                    <Button
                        id="settingsButton"
                        onClick={(event) =>
                            setSettings(settings ? null : event.currentTarget)
                        }
                        sx={{
                            background: 'transparent',
                            minWidth: '40px',
                            height: '40px',
                            padding: 0,
                            borderRadius: '50%',
                            position: 'absolute',
                            left: '20px',
                            bottom: '20px',
                            marginTop: '8px',
                            color: 'black',
                            '&:hover': {
                                color: 'darkred',
                            },
                        }}
                    >
                        <Icon
                            path={mdiCog}
                            size={1.4}
                            style={{ color: 'inherit' }}
                        />
                    </Button>
                    <Popover
                        open={Boolean(settings)}
                        anchorEl={settings}
                        onClose={() => setSettings(null)}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        PaperProps={{ id: 'settings', sx: { borderRadius: 0 } }}
                    >
                        <>
                            <FormControlLabel
                                sx={{ padding: '8px', paddingLeft: '10px' }}
                                control={
                                    <Switch
                                        checked={autoPlay}
                                        onChange={(e) =>
                                            setAutoPlay(e.target.checked)
                                        }
                                        sx={{
                                            '&.MuiSwitch-root .Mui-checked': {
                                                color: 'darkred',
                                            },
                                            '& .MuiSwitch-track': {
                                                backgroundColor:
                                                    'darkred !important',
                                            },
                                        }}
                                        disabled={type === 'track'}
                                    />
                                }
                                label="Autoplay"
                            />
                            <br />
                            <FormControlLabel
                                sx={{
                                    padding: '8px',
                                    paddingTop: 0,
                                    paddingBottom: '10px',
                                    paddingLeft: '10px',
                                }}
                                control={
                                    <Switch
                                        checked={separate}
                                        onChange={(e) =>
                                            setSeparate(e.target.checked)
                                        }
                                        sx={{
                                            marginBottom: 'auto',
                                            marginTop: '-5px',
                                            '&.MuiSwitch-root .Mui-checked': {
                                                color: 'darkred',
                                            },
                                            '& .MuiSwitch-track': {
                                                backgroundColor:
                                                    'darkred !important',
                                            },
                                        }}
                                    />
                                }
                                label={
                                    <div>
                                        "Auflösung" und "nachfolgender Track"
                                        <br />
                                        voneinander unabhängig
                                    </div>
                                }
                            />
                        </>
                    </Popover>
                    <Button
                        onClick={generateQR}
                        sx={{
                            background: 'transparent',
                            minWidth: '40px',
                            height: '40px',
                            padding: 0,
                            borderRadius: '50%',
                            position: 'absolute',
                            right: '20px',
                            bottom: '20px',
                            marginTop: '8px',
                            color: 'black',
                            '&:hover': {
                                color: 'darkred',
                            },
                        }}
                    >
                        <Icon
                            path={mdiQrcode}
                            size={1.4}
                            style={{ color: 'inherit' }}
                        />
                    </Button>
                </>
            ) : (
                <div
                    style={{
                        position: 'absolute',
                        top: 'calc(50% - ((10vw + 10px + 20px) / 2))',
                        left: 'calc(50%)',
                        transform: 'translate(-50%, -50%)',
                        justifyContent: 'center',
                        display: 'grid',
                    }}
                >
                    <CircularProgress
                        sx={{
                            color: 'darkred',
                            margin: 'auto',
                            marginBottom: '10px',
                        }}
                        size="10vw"
                    />
                    <div style={{ lineHeight: '20px', height: '20px' }}>
                        {window.location.pathname.split('/')[1] === 'tracks'
                            ? 'Track wird geladen'
                            : 'Tracks werden geladen'}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Player;
