import {
    TRACK_GET_FAIL,
    TRACK_GET_SUCCESS,
    TRACK_LOADING,
} from '../actions/types';

import spotify from '../spotifyAxiosInstance';

// check token & load user
export const getTracks = (word) => (dispatch) => {
    dispatch({
        type: TRACK_LOADING,
    });
    const config = {
        method: 'GET',
        url: `/search?type=track&q=${word}&limit=50`,
        success: (res) => {
            dispatch({
                type: TRACK_GET_SUCCESS,
                payload: res.data.tracks.items.filter(
                    (track) => track !== null
                ),
            });
        },
        error: (err) => {
            dispatch({
                type: TRACK_GET_FAIL,
            });
            console.error(err.message);
        },
    };
    spotify(config)
        .then((res) => {
            res.config.success(res);
        })
        .catch((err) => {
            err.config.error(err);
        });
};
