import { combineReducers } from 'redux';

import authReducer from './authReducer';
import playlistReducer from './playlistReducer';
import trackReducer from './trackReducer';
import queueReducer from './queueReducer';

export default combineReducers({
    auth: authReducer,
    playlist: playlistReducer,
    track: trackReducer,
    queue: queueReducer,
});
