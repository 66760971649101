import {
    PLAYLIST_GET_FAIL,
    PLAYLIST_GET_SUCCESS,
    PLAYLIST_LOADING,
} from '../actions/types';

import spotify from '../spotifyAxiosInstance';

// check token & load user
export const getPlaylists = (word) => (dispatch) => {
    dispatch({
        type: PLAYLIST_LOADING,
    });
    const config = {
        method: 'GET',
        url: `/search?type=playlist&q=${word}&limit=50`,
        success: (res) => {
            dispatch({
                type: PLAYLIST_GET_SUCCESS,
                payload: res.data.playlists.items.filter(
                    (playlist) => playlist !== null
                ),
            });
        },
        error: (err) => {
            dispatch({
                type: PLAYLIST_GET_FAIL,
            });
            alert(err.message);
            console.error(err.message);
        },
    };
    spotify(config)
        .then((res) => {
            res.config.success(res);
        })
        .catch((err) => {
            err.config.error(err);
        });
};
