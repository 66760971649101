import React from 'react';

import { Route, Routes } from 'react-router-dom';

import './App.css';

import Home from './components/Home';
import Playlists from './components/Playlists';
import Player from './components/Player';
import PrivateRoute from './components/PrivateRoute';
import Tracks from './components/Tracks';

function App() {
    return (
        <Routes>
            <Route
                index
                element={
                    <PrivateRoute>
                        <Home />
                    </PrivateRoute>
                }
            />
            <Route
                path="playlists"
                element={
                    <PrivateRoute>
                        <Playlists />
                    </PrivateRoute>
                }
            />
            <Route
                path="tracks"
                element={
                    <PrivateRoute>
                        <Tracks />
                    </PrivateRoute>
                }
            />
            <Route
                path="playlists/:id"
                element={
                    <PrivateRoute>
                        <Player type="playlist" />
                    </PrivateRoute>
                }
            />
            <Route
                path="tracks/:id"
                element={
                    <PrivateRoute>
                        <Player type="track" />
                    </PrivateRoute>
                }
            />
        </Routes>
    );
}

export default App;
