import { Box } from '@mui/material';

const Cover = ({ image, title, size, hover }) => {
    return (
        <div
            className="parent"
            style={{
                position: 'relative',
                height: size || '200px',
                width: size || '200px',
            }}
        >
            <div
                id="album"
                style={{
                    position: 'absolute',
                    height: '100%',
                    width: '100%',
                }}
            >
                <Box
                    id="cover"
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        zIndex: 1,
                        width: '100%',
                        height: '100%',
                        backgroundImage: `url(${image})`,
                        backgroundSize: '100% 100%',
                        boxShadow:
                            '5px 0 20px 0 rgba(0,0,0,0.2), inset 0 0 10px 5px rgba(255,255,255,0.2), inset 0 0 4px 2px rgba(0,0,0,0.2)',
                        borderRadius: '2px',
                        '&::before': {
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            zIndex: 10,
                            width: '100%',
                            height: '100%',
                            backgroundImage: "url('/old_vinyl.jpeg')",
                            backgroundSize: '100% 100%',
                            borderRadius: '2px',
                            mixBlendMode: 'screen',
                            opacity: 0.5,
                            content: '""',
                        },
                        '&::after': {
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            zIndex: 20,
                            width: '100%',
                            height: '100%',
                            backgroundImage: "url('/old_vinyl_2.jpeg')",
                            backgroundSize: '100% 100%',
                            borderRadius: '2px',
                            mixBlendMode: 'screen',
                            opacity: 0.2,
                            content: '""',
                        },
                    }}
                />
            </div>
            <Box
                sx={{
                    display: !hover ? 'inherit' : 'none',
                    position: 'absolute',
                    zIndex: 1,
                    bottom: 0,
                    color: 'white',
                    fontWeight: 'normal',
                    background: !hover ? 'rgba(0,0,0,.7)' : 'rgba(0,0,0,.6)',
                    padding: '8px',
                    width: 'calc(100% - 2 * 8px)',
                    '.parent:hover > &': {
                        display: 'inherit',
                    },
                }}
            >
                {title}
            </Box>
        </div>
    );
};

export default Cover;
