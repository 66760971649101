import { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { getTracks } from '../actions/trackActions';

import { Link, useNavigate } from 'react-router-dom';
import Cover from './Cover';
import {
    Button,
    CircularProgress,
    Grid2 as Grid,
    InputBase,
} from '@mui/material';

import Icon from '@mdi/react';
import { mdiMusic, mdiMagnify, mdiHomeCircleOutline } from '@mdi/js';
import { TRACK_GET_FAIL } from '../actions/types';

function Tracks() {
    const [word, setWord] = useState('');
    const dispatch = useDispatch();
    const tracks = useSelector((state) => state.track.items);
    const progress = useSelector((state) => state.track.progress);

    const navigate = useNavigate();

    return (
        <>
            <Button
                component={Link}
                to="/"
                onClick={() => dispatch({ type: TRACK_GET_FAIL })}
                sx={{
                    background: 'transparent',
                    minWidth: '40px',
                    height: '40px',
                    padding: 0,
                    borderRadius: '50%',
                    position: 'aboslute',
                    left: '20px',
                    top: '20px',
                    marginBottom: '8px',
                    color: 'darkred',
                    '&:hover': {
                        color: 'black',
                    },
                }}
            >
                <Icon
                    path={mdiHomeCircleOutline}
                    size={1.7}
                    style={{ color: 'inherit' }}
                />
            </Button>
            <div
                style={{
                    display: 'flex',
                    marginBottom: '30px',
                    marginTop: '20px',
                    justifyContent: 'center',
                    marginLeft: '20px',
                    marginRight: '20px',
                }}
            >
                <Button
                    type="button"
                    sx={{
                        background: 'black',
                        minWidth: '30px',
                        borderRadius: '50% 0% 0% 50%',
                        border: '1px solid black',
                    }}
                    disabled
                >
                    <Icon path={mdiMusic} size={1} style={{ color: 'white' }} />
                </Button>
                <div style={{ border: '1px solid black' }}>
                    <InputBase
                        sx={{ ml: 1, flex: 1, mr: 1, minWidth: '50vw' }}
                        autoFocus
                        placeholder="Track suchen"
                        value={word}
                        onChange={(e) => setWord(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.keyCode === 13 && word.trim().length > 0) {
                                dispatch(getTracks(word));
                            }
                        }}
                        slotProps={{
                            input: {
                                sx: { height: '27px' },
                            },
                        }}
                    />
                </div>
                <Button
                    type="button"
                    sx={{
                        background: 'black',
                        minWidth: '30px',
                        borderRadius: '0% 50% 50% 00%',
                        border: '1px solid black',
                        '&:hover': {
                            background: 'darkred',
                            borderColor: 'darkred',
                        },
                    }}
                    disabled={word.trim().length === 0 || progress}
                    onClick={() => dispatch(getTracks(word))}
                >
                    <Icon
                        path={mdiMagnify}
                        size={1}
                        style={{ color: 'white' }}
                    />
                </Button>
            </div>
            {progress ? (
                <div
                    style={{
                        position: 'absolute',
                        top: 'calc(50% - ((10vw + 10px + 20px) / 2))',
                        left: 'calc(50%)',
                        transform: 'translate(-50%, -50%)',
                        justifyContent: 'center',
                        display: 'grid',
                    }}
                >
                    <CircularProgress
                        sx={{
                            color: 'darkred',
                            margin: 'auto',
                            marginBottom: '10px',
                        }}
                        size="10vw"
                    />
                    <div
                        style={{
                            lineHeight: '20px',
                            height: '20px',
                            textAlign: 'center',
                        }}
                    >
                        übereinstimmende Tracks werden gesucht
                    </div>
                </div>
            ) : (
                <Grid
                    container
                    spacing={2}
                    sx={{ justifyContent: 'center', mb: 3 }}
                >
                    {tracks.map((track) => (
                        <Grid
                            item
                            key={track.id}
                            size={{ xs: '6', sm: '4', lg: '3', xl: '2' }}
                        >
                            <div
                                onClick={() => navigate(`/tracks/${track.id}`)}
                                style={{ cursor: 'pointer' }}
                            >
                                {track.album.images?.length > 0 ? (
                                    <Cover
                                        image={track.album.images[0].url}
                                        title={`${track.name} | ${track.artists
                                            .map((a) => a.name)
                                            .join(', ')}`}
                                    />
                                ) : null}
                            </div>
                        </Grid>
                    ))}
                </Grid>
            )}
        </>
    );
}

export default Tracks;
