import React from 'react';
import { createRoot } from 'react-dom/client';

import { Provider } from 'react-redux';
import store from './store';

import './index.css';

import { TourProvider } from '@reactour/tour';
import { BrowserRouter as Router } from 'react-router-dom';

import App from './App';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

const steps = [
    {
        selector: '#player',
        content:
            'Willkommmen! In den nachfolgenden Schritten erfährst du was man alles machen kann.',
    },
    {
        selector: '#vinyl',
        content:
            'Klicke den "Play"-Button zum Abspielen eines unbekannten Liedes. Habe dabei etwas Geduld, bis du den ersten Ton hörst.',
        highlightedSelectors: ['#vinyl'],
        mutationObservables: ['#vinyl'],
        action: () =>
            setTimeout(() => {
                window.dispatchEvent(new Event('resize'));
            }, 200),
    },
    {
        selector: '#vinyl',
        content:
            'Durch nochmaliges Klicken pausierst du das aktuelle Lied und kannst es jederzeit wieder weiterspielen.',
        highlightedSelectors: ['#vinyl'],
        mutationObservables: ['#vinyl'],
        action: () =>
            setTimeout(() => {
                window.dispatchEvent(new Event('resize'));
            }, 200),
    },
    {
        selector: '#solve',
        content:
            'Hast du eine Ahnung was das für ein Lied ist und wann es wohl erschienen ist? Klicke auf den "Noten"-Button, um es aufzulösen.',
        highlightedSelectors: ['#solve'],
        mutationObservables: ['#solve'],
        action: () =>
            setTimeout(() => {
                window.dispatchEvent(new Event('resize'));
            }, 200),
    },
    {
        selector: '#solveDialog',
        content: 'Und war deine Vermutung richtig?',
        highlightedSelectors: ['#solveDialog'],
        mutationObservables: ['#solveDialog'],
        action: () =>
            setTimeout(() => {
                window.dispatchEvent(new Event('resize'));
            }, 200),
    },
    {
        selector: '#next',
        content:
            'Genug gehört. Nun kommt das nächste Lied nach einem Klick auf den "Weiter"-Button.',
        highlightedSelectors: ['#next'],
        mutationObservables: ['#next'],
        action: () =>
            setTimeout(() => {
                window.dispatchEvent(new Event('resize'));
            }, 200),
    },
    {
        selector: '#vinyl',
        content: 'Starte erneut das Lied mit dem "Play"-Button oder ...',
        highlightedSelectors: ['#vinyl'],
        mutationObservables: ['#vinyl'],
        action: () =>
            setTimeout(() => {
                window.dispatchEvent(new Event('resize'));
            }, 200),
    },
    {
        selector: '#settingsButton',
        content: 'gehe in die Einstellungen ...',
        highlightedSelectors: ['#settingsButton'],
        mutationObservables: ['#settingsButton'],
        action: () =>
            setTimeout(() => {
                window.dispatchEvent(new Event('resize'));
            }, 200),
    },
    {
        selector: '#settings',
        content:
            'und aktiviere "Autoplay", um zukünftig automatisch die Lieder abgespielt zu bekommen.',
        highlightedSelectors: ['#settings'],
        mutationObservables: ['#settings'],
        action: () =>
            setTimeout(() => {
                window.dispatchEvent(new Event('resize'));
            }, 200),
    },
    {
        selector: '#historyButtton',
        content: 'Klicke auf den "Liste"-Button ...',
        highlightedSelectors: ['#historyButtton'],
        mutationObservables: ['#historyButtton'],
        action: () =>
            setTimeout(() => {
                window.dispatchEvent(new Event('resize'));
            }, 200),
    },
    {
        selector: '#history',
        content:
            'um dir die bisherigen gelösten Lieder anzusehen und erneut abzuspielen.',
        highlightedSelectors: ['#history'],
        mutationObservables: ['#history'],
        action: () =>
            setTimeout(() => {
                window.dispatchEvent(new Event('resize'));
            }, 200),
    },
    {
        selector: '#player',
        content: 'Nun kennst du alle wichtigen Funktionen - viel Spaß!',
    },
];

const container = document.getElementById('delucse');
const root = createRoot(container);
root.render(
    <Router>
        <Provider store={store}>
            <TourProvider
                steps={steps}
                onClickMask={({
                    setCurrentStep,
                    currentStep,
                    steps,
                    setIsOpen,
                }) => {
                    if (steps) {
                        if (currentStep === steps.length - 1) {
                            setIsOpen(false);
                        }
                        setCurrentStep((s) =>
                            s === steps.length - 1 ? 0 : s + 1
                        );
                    }
                }}
                styles={{
                    popover: (base) => ({
                        ...base,
                        '--reactour-accent': 'darkred',
                        borderRadius: 0,
                    }),
                    maskArea: (base) => ({ ...base }),
                    maskWrapper: (base) => ({ ...base }),
                    badge: (base) => ({
                        ...base,
                        border: '1px solid white',
                    }),
                    controls: (base) => ({ ...base }),
                    close: (base) => ({ ...base }),
                }}
            >
                <App />
            </TourProvider>
        </Provider>
    </Router>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
