export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';

// playlist
export const PLAYLIST_GET_FAIL = 'PLAYLIST_GET_FAIL';
export const PLAYLIST_GET_SUCCESS = 'PLAYLIST_GET_SUCCESS';
export const PLAYLIST_LOADING = 'PLAYLIST_LOADING';

// track
export const TRACK_GET_FAIL = 'TRACK_GET_FAIL';
export const TRACK_GET_SUCCESS = 'TRACK_GET_SUCCESS';
export const TRACK_LOADING = 'TRACK_LOADING';

// queue
export const QUEUE_GET_FAIL = 'QUEUE_GET_FAIL';
export const QUEUE_GET_SUCCESS = 'QUEUE_GET_SUCCESS';
export const QUEUE_LOADING = 'QUEUE_LOADING';
export const QUEUE_NEXT = 'QUEUE_NEXT';
export const QUEUE_NAME = 'QUEUE_NAME';
