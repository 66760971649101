import { Box } from '@mui/material';

const Vinyl = ({ spin, children, onClick, style }) => {
    return (
        <div
            style={{
                position: 'relative',
                height: 'min(50vh, 75vw)',
                width: 'min(50vh, 75vw)',
                ...style,
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '2.5%',
                    left: '2.5%',
                    zIndex: 0,
                    width: '95%',
                    height: '95%',
                    display: 'block',
                    backgroundImage: "url('/vinyl.png')",
                    backgroundSize: '106% 106%',
                    backgroundPosition: 'center',
                    boxShadow: '0 0 20px 4px rgba(0,0,0,0.6)',
                    borderRadius: '50%',
                    content: '""',
                    animation: spin ? '5s linear spin infinite' : 'none',
                }}
            />
            <div
                id="vinyl"
                onClick={onClick}
                style={{
                    position: 'absolute',
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '50%',
                    cursor: 'pointer',
                }}
            >
                {children}
            </div>
        </div>
    );
};

export default Vinyl;
