import { AUTH_FAIL, AUTH_SUCCESS } from '../actions/types';

const getTokenFromUrl = () => {
    const hash = window.location.hash;
    window.location.hash = '';
    return hash
        .substring(1)
        .split('&')
        .reduce((initial, item) => {
            let parts = item.split('=');
            initial[parts[0]] = decodeURIComponent(parts[1]);
            return initial;
        }, {});
};

const urlHash = getTokenFromUrl();
// alert(urlHash.access_token ? urlHash.access_token : 'kein Token');

const initialState = {
    token: urlHash.access_token,
    refreshToken: urlHash.refresh_token,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case AUTH_FAIL:
            return {
                ...state,
                token: null,
                refreshToken: null,
            };
        case AUTH_SUCCESS:
            return {
                ...state,
                token: action.payload.token,
                refreshToken: action.payload.refreshToken,
            };
        default:
            return state;
    }
};

export default reducer;
