import {
    TRACK_GET_FAIL,
    TRACK_GET_SUCCESS,
    TRACK_LOADING,
} from '../actions/types';

const initialState = {
    items: [],
    progress: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case TRACK_GET_FAIL:
            return {
                ...state,
                items: [],
                progress: false,
            };
        case TRACK_GET_SUCCESS:
            return {
                ...state,
                items: action.payload,
                progress: false,
            };
        case TRACK_LOADING:
            return {
                ...state,
                progress: true,
            };
        default:
            return state;
    }
};

export default reducer;
