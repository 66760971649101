import {
    QUEUE_GET_FAIL,
    QUEUE_GET_SUCCESS,
    QUEUE_LOADING,
    QUEUE_NEXT,
} from '../actions/types';

import isrc from '../isrcAxiosInstance';
import spotify from '../spotifyAxiosInstance';

// https://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array
// Randomize array in-place using Durstenfeld shuffle algorithm
function shuffleArray(array) {
    for (var i = array.length - 1; i >= 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        var temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    }
    return array;
}

const searchArtistTrack = (artist, track, offset, data = []) => {
    const config = {
        method: 'GET',
        url: `/search?type=track&q=track:${track} artist:${artist}&limit=50${
            offset ? `&offset=${offset}` : ''
        }`,
        success: (res) => {
            if (data && data.length > 0) {
                data = data.concat(res.data.tracks.items);
            } else {
                data = res.data.tracks.items;
            }
            if (res.data.tracks.next) {
                return searchArtistTrack(
                    artist,
                    track,
                    res.data.tracks.offset + res.data.tracks.limit,
                    data
                );
            }
            return data;
        },
        error: (err) => {
            console.error(err.message);
            return data;
        },
    };
    return spotify(config)
        .then((res) => {
            return res.config.success(res);
        })
        .catch((err) => {
            console.log(err);
            return err.config.error(err);
        });
};

const searchISRC = (id, offset, data = []) => {
    const config = {
        method: 'GET',
        url: `/search?type=track&q=isrc:${id}&limit=50${
            offset ? `&offset=${offset}` : ''
        }`,
        success: (res) => {
            if (data && data.length > 0) {
                data = data.concat(res.data.tracks.items);
            } else {
                data = res.data.tracks.items;
            }
            if (res.data.tracks.next) {
                return searchISRC(
                    id,
                    res.data.tracks.offset + res.data.tracks.limit,
                    data
                );
            }
            return data;
        },
        error: (err) => {
            console.error(err.message);
            return data;
        },
    };
    return spotify(config)
        .then((res) => {
            return res.config.success(res);
        })
        .catch((err) => {
            console.log(err);
            return err.config.error(err);
        });
};

// eslint-disable-next-line
const findISRC = (id) => {
    const config = {
        method: 'POST',
        url: `/recordings`,
        data: {
            searchFields: {
                isrc: id,
            },
            start: 0,
            number: 10,
            showReleases: false,
        },
        success: (res) => {
            console.log(res.data);
        },
        error: (err) => {
            console.error(err.message);
        },
    };
    isrc(config)
        .then((res) => {
            res.config.success(res);
        })
        .catch((err) => {
            err.config.error(err);
        });
};

const getPlaylistTracks = (id, offset, data = []) => {
    const config = {
        method: 'GET',
        url: `/playlists/${id}/tracks${offset ? `?offset=${offset}` : ''}`,
        success: (res) => {
            if (data && data.length > 0) {
                data = data.concat(res.data.items.map((data) => data.track));
            } else {
                data = res.data.items.map((data) => data.track);
            }
            if (res.data.next) {
                return getPlaylistTracks(
                    id,
                    res.data.offset + res.data.limit,
                    data
                );
            }
            return data;
        },
        error: (err) => {
            alert(err.message);
            console.error(err.message);
            return data;
        },
    };
    return spotify(config)
        .then((res) => {
            return res.config.success(res);
        })
        .catch((err) => {
            console.log(err);
            return err.config.error(err);
        });
};

const getTrack = (id) => {
    const config = {
        method: 'GET',
        url: `/tracks/${id}`,
        success: (res) => {
            return res.data;
        },
        error: (err) => {
            console.error(err.message);
            return;
        },
    };
    return spotify(config)
        .then((res) => {
            return res.config.success(res);
        })
        .catch((err) => {
            console.log(err);
            return err.config.error(err);
        });
};

const getPlaylistName = (id) => {
    const config = {
        method: 'GET',
        url: `/playlists/${id}?fields=name`,
        success: (res) => {
            return res.data.name;
        },
        error: (err) => {
            console.error(err.message);
            return;
        },
    };
    return spotify(config)
        .then((res) => {
            return res.config.success(res);
        })
        .catch((err) => {
            console.log(err);
            return err.config.error(err);
        });
};

export const getQueue = (id, type) => async (dispatch, getState) => {
    dispatch({
        type: QUEUE_LOADING,
    });
    if (type === 'playlist') {
        var tracks = await getPlaylistTracks(id, 0);
        if (!tracks) {
            dispatch({ type: QUEUE_GET_FAIL });
        } else {
            tracks = shuffleArray(tracks.filter((track) => track !== null));
            tracks = await findRightYear(tracks);
            const payload = {
                items: tracks,
            };
            if (!getState().queue.name) {
                payload.name = await getPlaylistName(id);
            }
            dispatch({
                type: QUEUE_GET_SUCCESS,
                payload,
            });
        }
    } else {
        const track = await getTrack(id);
        if (!track) {
            dispatch({ type: QUEUE_GET_FAIL });
        } else {
            const tracks = await findRightYear([track]);
            dispatch({
                type: QUEUE_GET_SUCCESS,
                payload: {
                    items: tracks,
                    name: '',
                },
            });
        }
    }
};

const findRightYear = async (items) => {
    if (items.length > 0) {
        var results = [];
        if (items[0]?.external_ids?.isrc)
            results = results.concat(
                await searchISRC(items[0].external_ids.isrc, 0)
            );
        if (items[0].name && items[0].album?.artists) {
            const resultsArtistsTrack = await searchArtistTrack(
                items[0].album.artists.map((a) => a.name).join(' '),
                items[0].name,
                0
            );
            results = results.concat(
                resultsArtistsTrack.filter(
                    (track) =>
                        track.album.artists.length ===
                        items[0].album.artists.length
                )
            );
        }
        if (results.length > 0) {
            items[0] = results.sort(
                (a, b) =>
                    new Date(a.album.release_date) -
                    new Date(b.album.release_date)
            )[0];
        }
    }
    return items;
};

export const setNext = () => async (dispatch, getState) => {
    var { items, played } = getState().queue;
    played.unshift(items[0]);
    items.shift();
    items = await findRightYear(items);
    dispatch({
        type: QUEUE_NEXT,
        payload: {
            items: [...items],
            played: [...played],
        },
    });
};

export const play = (device, uri, position) => {
    const config = {
        method: 'PUT',
        url: `/me/player/play?device_id=${device}`,
        headers: {
            'Content-Type': 'application/json',
        },
        success: (res) => {},
        error: (err) => {
            console.error(err.message);
        },
    };
    const data = {};
    if (uri) data.uris = [uri];
    if (position) data.position_ms = position;
    if (data.uris || data.position_ms) {
        config.data = JSON.stringify(data);
    }
    spotify(config)
        .then((res) => {
            res.config.success(res);
        })
        .catch((err) => {
            err.config.error(err);
        });
};

export const pause = (device) => {
    const config = {
        method: 'PUT',
        url: `/me/player/pause?device_id=${device}`,
        headers: {
            'Content-Type': 'application/json',
        },
        success: (res) => {},
        error: (err) => {
            console.error(err.message);
        },
    };
    spotify(config)
        .then((res) => {
            res.config.success(res);
        })
        .catch((err) => {
            err.config.error(err);
        });
};
